<template>
  <div class="mobile_view">
    <img src="/media/logos/rmd_logo.png" height="55px;" style="margin: 10px 78px" />
    &nbsp;
    <div
      style="
        text-align: center;
        margin-top: 0;
        margin-bottom: 1rem;
        width: 254px;
      "
    >
      <h1>RefineMyData</h1>
    </div>
    <br />
    <div
      style="
        text-align: center;
        margin-top: 0;
        margin-bottom: 1rem;
        width: 254px;
      "
    >
      <h3>
        RefineMyData does not support mobile phones. Please visit this page on a
        tablet or a computer.
      </h3>
      <br />
      <h3>
        If you wish to get in touch, please contact us via LiveChat by selecting
        the chat icon below.
      </h3>
      <br />
      <h3>
      <a href="https://www.refinemydata.com" >
        <span >
            Visit our Website
        </span>
      </a>
    </h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "Mobile",
  mounted() {
    // Redirect after 10 seconds
    setTimeout(() => {
      window.location.href = "https://www.refinemydata.com";
    }, 10000); // 10000 milliseconds = 10 seconds
  },
};
</script>

<style scoped>
.mobile_view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  height: 100%;
}
a {
    text-decoration: underline !important;
    color: #33B985 !important
  }
</style>
